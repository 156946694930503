import logo from './logo.svg';
import './App.css';

import { BrowserRouter, Route, Routes } from 'react-router-dom'

import Home from './pages/Home';
import Study from './pages/Study';
import Store from './pages/Store';
import More from './pages/More';

import AboutUs from './pages/AboutUs'
import ContactUs from './pages/ContactUs';
import PrivacyPolicy from './pages/PrivacyPolicy'
import Disclaimer from './pages/Disclaimer'
import PageNotFound from './pages/PageNotFound';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>


          <Route path='/' element={<Home />}></Route>
          <Route path='/study' element={<Study />}></Route>
          <Route path='/store' element={<Store />}></Route>
          <Route path='/more' element={<More />}></Route>
          <Route path='/privacy-policy' element={<PrivacyPolicy />}></Route>
          <Route path='/about-us' element={<AboutUs />}></Route>
          <Route path='/disclaimer' element={<Disclaimer />}></Route>
          <Route path='/contact-us' element={<ContactUs />}></Route>
          

          <Route path='*' element={<PageNotFound />}></Route>


        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
