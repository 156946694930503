import React from 'react'
import BottomBar from '../component/BottomBar'

import Carousel from '../page-store/Carousal'
import Options from '../page-store/Options'

import commingSoon from '../images/coming-soon.jpg'


export default function Store() {
  return (
    <div>

        {/* <Carousel /> */}

        <Options />




        <BottomBar selectStore="true" />

    </div>
  )
}
